import cx from 'classnames';
import { useChain } from 'components/Chain/hooks';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import {
  ChainName,
  ChainUniqueName,
  EcosystemChainId,
} from 'types/emoney/Chain';
import s from './Icon.less';

export function getSrc(chainName: ChainName, isLarge: boolean) {
  return `/assets/emoney/chains/${chainName}-icon-${isLarge ? 'lg' : 'sm'}.svg`;
}

const Icon = ({
  className = '',
  isLarge = false,
  chainName,
}: {
  className?: string;
  isLarge?: boolean;
  chainName?: ChainName | ChainUniqueName | EcosystemChainId;
}) => {
  useStyles(s);
  const { chain } = useChain(chainName);
  return chainName && chain ? (
    <img
      className={cx(className, s.image)}
      src={getSrc(chain.chain, isLarge)}
      alt={chain.label}
      title={chain.label}
    />
  ) : (
    <span
      className={cx('material-icons', className, s.image, s.unknown)}
      title={chainName as string}
    >
      question_mark
    </span>
  );
};

export default Icon;
