import chains from 'components/Chain/chains';
import { useLogger } from 'services/logger';
import { Chain, EcosystemChainId, EcosystemId } from 'types/emoney/Chain';
import {
  Connector,
  useAccount as useWagmiAccount,
  useConnect as useWagmiConnect,
  useDisconnect as useWagmiDisconnect,
} from 'wagmi';
import { useCosmosProvider, useCosmosSignMessage } from './cosmos/hooks';
import { useEvmSignMessage } from './evm/hooks';
import { SignatureData } from './types';

export const useDisconnect = (kind?: EcosystemId) => {
  const { disconnect: disconnectCosmos } = useCosmosProvider();
  const { disconnectAsync: disconnectEvm } = useWagmiDisconnect();

  const disconnect = async ({ connector }: { connector?: Connector } = {}) => {
    if (kind === 'cosmos') {
      // Disconnect from cosmos
      disconnectCosmos();
    } else if (kind === 'evm') {
      // Disconnect from evm
      await disconnectEvm({ connector });
    } else if (!kind) {
      // Disconnect from all
      disconnectCosmos();
      await disconnectEvm({ connector });
    }
  };

  return { disconnect };
};

export const useConnect = (kind: EcosystemId) => {
  const { connect: connectCosmos } = useCosmosProvider();
  const { connectAsync: connectEvm, connectors } = useWagmiConnect();

  const connect = async (
    props?: { connector?: Connector; chainId?: EcosystemChainId },
    handlers?: { onSuccess: () => void; onError: (e: Error) => void },
  ) => {
    if (kind === 'cosmos') {
      try {
        await connectCosmos();
        handlers?.onSuccess?.();
      } catch (error) {
        handlers?.onError?.(error as Error);
      }
      return;
    } else {
      let connector = props?.connector;
      if (!props?.connector) {
        connector = connectors.find((c) => c.type === 'injected');
      }
      if (!connector) {
        handlers?.onError(Error('connector is required for EVM chains.'));
        return;
      }

      return connectEvm(
        { connector, chainId: Number(props?.chainId) },
        {
          onSuccess: handlers?.onSuccess,
          onError: handlers?.onError,
        },
      );
    }
  };

  return { connect };
};

export const useConnected = (
  kind: EcosystemId,
): { address: string; chain: Chain } => {
  const { address: evmAddress, chainId: evmChainId } = useWagmiAccount();
  const { address: cosmosAddress, chainId: cosmosChainId } =
    useCosmosProvider();

  if (kind === 'cosmos') {
    return {
      address: cosmosAddress,
      chain: chains.find((c) => c.chainId === cosmosChainId) as Chain,
    };
  } else {
    return {
      address: evmAddress as string,
      chain: chains.find((c) => c.chainId === evmChainId?.toString()) as Chain,
    };
  }
};

export const useSignMessage = (
  chain: Chain,
): {
  signatureData?: SignatureData;
  signMessage: (message: string) => Promise<SignatureData | undefined>;
  isLoading: boolean;
  error: unknown;
} => {
  const { logMessage } = useLogger();
  const {
    signature: evmSignature,
    signMessage: signEvmMessage,
    error: evmError,
    isLoading: evmIsLoading,
  } = useEvmSignMessage(chain);
  const {
    signatureData: cosmosSignatureData,
    signMessage: signCosmosMessage,
    error: cosmosError,
    isLoading: cosmosIsLoading,
  } = useCosmosSignMessage();

  const signMessage = async (message: string) => {
    if (!chain?.kind) {
      logMessage({
        message: 'Cant sign message. Chain info missing.',
        severity: 'error',
      });
      return;
    }
    if (chain.kind === 'cosmos') {
      return await signCosmosMessage(message);
    } else {
      return {
        signature: await signEvmMessage(message),
      };
    }
  };

  let signatureData;
  if (chain.kind === 'cosmos') {
    if (cosmosSignatureData) {
      signatureData = cosmosSignatureData;
    }
  } else {
    if (evmSignature) {
      signatureData = {
        signature: evmSignature,
      };
    }
  }

  return {
    signatureData: signatureData,
    signMessage,
    isLoading: chain.kind === 'cosmos' ? cosmosIsLoading : evmIsLoading,
    error: chain.kind === 'cosmos' ? cosmosError : evmError,
  };
};
